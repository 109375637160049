// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import JQuery from "jquery";
window.$ = window.JQuery = JQuery;

import "@fortawesome/fontawesome-free/css/all"
import "../js/bootstrap_js_files.js"
import "../js/registration.js"
import "../js/ecommerce_websites.js"

// import Taggier from 'taggier';

document.addEventListener("DOMContentLoaded", function(event) {
  const myOptions = {
    gap: 8,
    forbiddenPattern: false
  };
  // window.taggier = new Taggier('domain-list', myOptions);
  // taggier.getTags();
})

Rails.start()
ActiveStorage.start()