$(document).ready(function () {
  $(".get-started-registration-form select#engine_type").on('change', function(){
    var engine_selected = $( ".get-started-registration-form select#engine_type option:selected" ).val();

    if (engine_selected == 'shopify') {
      $('.get-started-registration-form .shopify-registration').show();
      $('.get-started-registration-form .woo-commerce-registration').hide();
      $('.get-started-registration-form .right-image').show();
      $('.get-started-registration-form .woo-commerce-steps').hide();

    } else if (engine_selected == 'woo_commerce') {
      $('.get-started-registration-form .shopify-registration').hide();
      $('.get-started-registration-form .woo-commerce-registration').show();
      $('.get-started-registration-form .right-image').hide();
      $('.get-started-registration-form .woo-commerce-steps').show();

    } else {
      $('.get-started-registration-form .shopify-registration').hide();
      $('.get-started-registration-form .woo-commerce-registration').hide();
      $('.get-started-registration-form .woo-commerce-steps').hide();
      $('.get-started-registration-form .right-image').show();
    }
  });

  if ($("#engine_type").val() == 'woo_commerce') { // When errors occur in the registration form we render again and want to display the woo commerce form
    $( ".get-started-registration-form select#engine_type" ).val('woo_commerce').trigger('change');
  }
});