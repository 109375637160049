$(document).ready(function () {
  $('.choose-engine-dropdown-menu a').click(function(){
    var engine_selected = $(this).text();
    if (engine_selected == 'Shopify') {
      $('.ecommerce-websites-new .shopify-connect').show();
      $('.ecommerce-websites-new .right-home-background-image').show();
      $('.ecommerce-websites-new .woo-commerce-connect').hide();
      $('.ecommerce-websites-new .woo-commerce-steps').hide();
      $('.ecommerce-websites-new .vl').hide();
    } else if (engine_selected == 'WooCommerce') {
      $('.ecommerce-websites-new .woo-commerce-connect').show();
      $('.ecommerce-websites-new .woo-commerce-steps').show();
      $('.ecommerce-websites-new .vl').show();
      $('.ecommerce-websites-new .shopify-connect').hide();
      $('.ecommerce-websites-new .right-home-background-image').hide();
    }
  });

  $('#wooCommerceRedirectionModal .modal-continue-btn').on('click', function(){
    $('.woo-commerce-domain form#new_ecommerce_website').submit();
  });
});
